.proj-img--hover {
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(128, 128, 128, 0.829);
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  color: white;
  font-size: 25px;
}

.display {
  display: flex;
}

.proj-descr {
  text-align: center;
}
.visit {
  text-align: center;
  position: relative;

  color: white;
  font-style: italic;
  font-size: 18px;
  margin-bottom: 10px;
}

.proj-name > a {
  text-decoration: none;
  text-decoration: unset;
  color: white;
  cursor: pointer;
}
