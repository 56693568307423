.shape--triangle-r.about {
  transform: rotateY(180deg);
}

.container--about {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
}

.container--about-main {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.container--about-descr {
  display: flex;
  justify-content: center;
  width: 45%;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.39);
  border-radius: 15px;
  padding: 8px 15px;
}

.about-descr {
  width: 100%;
  color: white;
  font-size: 20px;
  max-height: 560px;
  /* overflow-y: scroll; */
  padding-bottom: 10px;
}

.profile-pic {
  position: relative;
  width: 45%;
  overflow: hidden;
  height: 550px;
  border-left: 10px inset white;
  border-top: 8px solid rgb(206, 84, 84);
  border-style: groove;
  border-bottom: 8px groove white;
  border-right: 8px groove rgb(206, 84, 84);
  border-bottom-left-radius: 125px;
}

.profile-pic > img {
  width: 100%;
  height: auto;
  position: relative;
  top: -55px;
}

.hi {
  font-size: 70px;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.342);
}

.wavemoji {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.about-descr > a {
  color: rgb(202, 34, 34);
  font-weight: 600;
  text-decoration: none;
}

@media only screen and (max-width: 411px) {
  .container--about-main {
    flex-direction: column-reverse;
    justify-content: flex-end;
    height: 100%;
  }
  .about-descr {
    overflow-y: scroll;
  }
  .profile-pic {
    width: 90%;
    height: 35%;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .profile-pic > img {
    top: unset;
  }
  .container--about-descr {
    width: 90%;
    height: 46%;
  }
  .wavemoji > img {
    height: auto;
    width: 70px;
  }
  .hi {
    font-size: 50px;
  }
}

@media only screen and (max-height: 850px) {
  .profile-pic {
    height: 500px;
  }
  .profile-pic > img {
  }
  .container--about-main {
    align-self: flex-start;
    margin-top: 5%;
  }
  .container--about-descr {
  }
  .about-descr {
    font-size: 16px;
    max-height: 540px;
  }
}
