.container--projects {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.projects.top-square {
  position: absolute;
  z-index: -1;
  top: 0;
  clip-path: polygon(0% 0%, 100% 0%, 100% 48%, 0% 48%);
  width: 100%;
  height: 100%;
  background: rgb(247, 234, 193);
}

.projects.bottom-square {
  z-index: -1;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  clip-path: polygon(0% 100%, 100% 100%, 100% 52%, 0% 52%);
  background: rgb(206, 84, 84);
}

.projects.mid-circle {
  position: absolute;
  z-index: -1;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.342);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.473);
  width: 400px;
  height: 400px;
}

.container--proj-profiles {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 625px;
}

.outer {
  position: relative;
  display: flex;
  max-width: 495px;
  flex-direction: column;
  margin: 0 10px;
  margin-bottom: 25px;
}

.container--proj {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  background: rgba(0, 0, 0, 0.514);
  border-radius: 10px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.527);
  border: 10px ridge white;
}

.proj-img {
  position: relative;
  height: 300px;
  width: 450px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.proj-img > img {
  height: 100%;
  width: auto;
}

.container--proj-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  justify-content: center;
  border-bottom: 3px solid skyblue;
  border-top: 3px solid skyblue;
  margin-top: 10px;
}

.container--proj-role {
  width: 100%;

  display: flex;
  justify-content: center;
}

.proj-role {
  font-size: 19px;
  font-weight: 400;
  text-align: center;
  color: white;
  width: 90%;
  background: rgba(71, 71, 71, 0.562);
  padding: 15px 8px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom: 10px solid white;
  max-height: 100px;
  min-height: 100px;
  overflow-y: scroll;
}
.proj-name {
  text-align: center;
  font-size: 35px;
  color: white;
  cursor: pointer;
}

.stack {
  font-size: 18px;
  color: white;
  margin: 15px 0;
  height: 30px;
  width: 95%;
  background: gray;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 8px;
  border-right: 3px solid white;
  border-left: 3px solid white;
}

.stack > span {
  font-weight: 500;
}

.red {
  color: rgb(211, 3, 3);
}

.green {
  color: white;
}

.orange {
  color: rgb(247, 234, 193);
}

.purple {
  color: #c7e9ff;
}
.click {
  display: none;
  color: white;
  font-style: italic;
  font-size: 10px;
}

.cpn {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 411px) {
  .outer {
  }
  .stack {
    width: 100%;
    font-size: 16px;
  }
  .proj-img {
    height: auto;
    width: 100%;
  }
  .proj-img > img {
    width: 100%;
  }
  .container--proj {
    padding: 8px;
  }
  .visit {
  }
  .proj-descr {
  }
  .proj-name {
  }
  .container--proj-role {
  }
  .proj-role {
  }
  .container--proj-profiles {
    display: unset;
    margin-bottom: 100px;
  }
  .click {
    display: unset;
  }
}
